import { useEffect } from "react";
import { Route, Routes, useNavigate, Navigate, useLocation } from "react-router-dom";
import { useAuthState, useSignInWithEmailLink, useSignInWithGoogle } from "react-firebase-hooks/auth";
import { isSignInWithEmailLink } from "firebase/auth";
import { BlockGamesBanner } from "../../components/BlockGamesLogo/BlockGamesBanner";
import { firebaseAuth } from "../../components/Firebase/Firebase";
import { SignInWithEmail } from "./SignInWithEmail";
import { SelectSignInMethod } from "./SelectSignInMethod";
import { UserDashboard } from "./components/UserDashboard";
import { KarluqContainer } from "./components/KarluqContainer";
import { LoaderIcon } from "../../components/SVGs/LoaderIcon";
import { ResetEmailPassword } from "./ResetEmailPassword";
import { RegisterEmailAccount } from "./RegisterEmailAccount";
import { CreateNewPassword } from "./CreateNewPassword";

export const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [user] = useAuthState(firebaseAuth);
  // eslint-disable-next-line
  const [signInWithEmailLink, emailUser, loadingEmail, errorEmail] = useSignInWithEmailLink(firebaseAuth);
  // eslint-disable-next-line
  const [signInWithGoogle, googleUser, loadingGoogle, errorGoogle] = useSignInWithGoogle(firebaseAuth);

  useEffect(() => {
    const signIn = async () => {
      if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
        let email = window.localStorage.getItem("emailForSignIn");

        if (!email) {
          navigate({
            pathname: "/validate-email",
            search: window.location.search,
          });

          return;
        }

        await signInWithEmailLink(email as string, window.location.href);
        navigate("/");
        window.localStorage.removeItem("emailForSignIn");
      }
    };

    signIn();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user && state?.redirect) {
      navigate(state.redirect);
    }
    // eslint-disable-next-line
  }, [user]);

  if (loadingEmail || loadingGoogle) {
    return (
      <KarluqContainer>
        <LoaderIcon />
      </KarluqContainer>
    );
  }

  return (
    <KarluqContainer>
      <div className="w-full">
        {user ? (
          <UserDashboard user={user} />
        ) : (
          <Routes>
            <Route
              path="/login/method"
              element={
                <>
                  <SelectSignInMethod
                    onGoogleClick={() => signInWithGoogle()}
                    onEmailClick={() => navigate("/login/email", { state })}
                  />
                  <BlockGamesBanner className="mt-6" />
                </>
              }
            />

            <Route path="/login/email" element={<SignInWithEmail />} />
            <Route path="/reset-password" element={<ResetEmailPassword />} />
            <Route path="/create-new-password" element={<CreateNewPassword />} />
            <Route path="/register" element={<RegisterEmailAccount />} />
            <Route path="/*" element={<Navigate to="/login/method" />} />
          </Routes>
        )}
      </div>
    </KarluqContainer>
  );
};
