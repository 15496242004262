import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

export const initSentry = (): void => {
  if (process.env.REACT_APP_ENABLE_SENTRY !== "true") {
    return;
  }

  Sentry.init({
    dsn: "https://68869b5ca51946b4bb301bd718cdc66e@o1219097.ingest.sentry.io/4504713196077056",
    integrations: [
      new BrowserTracing(),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error"],
      }),
    ],

    maxBreadcrumbs: 50,
    tracesSampleRate: 5.0,
  });
};
