import { HTMLAttributes } from "react";
import styled from "styled-components";
import Karluq from "../../../assets/images/karluq.png";

const KarluqWrapper = styled.img<any>`
  @media (max-width: 500px) {
    max-width: 220px;
  }
`;

const ContentContainer = styled.div`
  max-width: 400px;
  width: 100%;

  @media (max-width: 992px) {
    max-width: 340px;
    margin: 0 auto;
  }
`;

export const KarluqContainer = ({ children, className, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...rest} className={`h-full min-h-screen flex items-center ${className || ""}`}>
      <div className="container grid grid-cols-1 md:grid-cols-2 mx-auto items-center pt-20 md:pt-0">
        <div className="w-full order-2 md:order-1 flex justify-center md:justify-end md:pr-9 ">
          <KarluqWrapper src={Karluq} alt="" />
        </div>
        <ContentContainer className="w-full flex flex-col order-1 md:order-2 justify-center md:pl-9 items-center md:items-start pt-3 lg:pb-10">
          {children}
        </ContentContainer>
      </div>
    </div>
  );
};
