import {
  InputHTMLAttributes,
  useState,
  ChangeEvent,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
  ReactNode,
} from "react";
import styled, { css } from "styled-components";

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  suffix?: ReactNode;
}

export type InputWrapperStyleProps = {
  hasValue?: boolean;
  focused?: boolean;
  hovered?: boolean;
};

const InputWrapper = styled.div<any>`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border-radius: 5px;
  background-color: #fff;
  overflow: hidden;
  height: 56px;
  min-width: 258px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    appearance: none;
    display: block;
    border: 0px;
    font-size: 16px;
    line-height: 1;
    min-width: 200px;
    background: none;
    padding: 0px 20px;
    box-sizing: border-box;
    padding-top: 10px;
    font-weight: 500;
    padding-right: 45px;
  }

  label {
    position: absolute;
    color: rgb(65, 70, 86);
    left: 20px;
    pointer-events: none;
    transition: cubic-bezier(0.25, 1, 0.5, 1);

    ${({ hasValue, focused }) =>
      focused || hasValue
        ? css`
            font-size: 12px;
            top: 7px;
          `
        : css`
            font-size: 16px;
            top: 15px;
          `};
  }
`;

const Suffix = styled.div`
  position: absolute;
  right: 17px;
  z-index: 334;
  top: 19px;
`;

export const Input = forwardRef<{ focus: () => void }, IInput>(
  ({ value, onChange, defaultValue, label, autoFocus, suffix, className, ...restProps }, ref) => {
    const [focused, setFocus] = useState(autoFocus ?? false);
    const [hasValue, setHasValue] = useState(!!defaultValue || !!value || defaultValue === 0 || value === 0);

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        focus: () => {
          !focused && setFocus(true);

          if (inputRef.current) {
            inputRef.current.focus();
          }
        },
      }),
      [focused]
    );

    useEffect(() => {
      setHasValue(!!defaultValue || !!value || defaultValue === 0 || value === 0);
    }, [value, defaultValue]);

    const onInnerChange = (evt: ChangeEvent<HTMLInputElement>) => {
      if (!evt.currentTarget.value && hasValue) {
        setHasValue(false);
      }

      if (evt.currentTarget.value && !hasValue) {
        setHasValue(true);
      }

      onChange && onChange(evt);
    };

    return (
      <InputWrapper
        className={className}
        hasValue={hasValue}
        focused={focused}
        onFocus={() => {
          !focused && setFocus(true);

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
        onBlur={() => focused && setFocus(false)}
        hasSuffix={!!suffix}
      >
        <label>{label}</label>
        <input {...restProps} ref={inputRef} value={value} onChange={onInnerChange} />
        {suffix && <Suffix>{suffix}</Suffix>}
      </InputWrapper>
    );
  }
);
