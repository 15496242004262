import { HTMLAttributes } from "react";
import styled from "styled-components";
import BGLogo from "../../assets/images/BG_logo.svg";

const Wrapper = styled.div`
  padding: 0 24px;

  span {
    width: 82px;
    margin-right: 11px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    opacity: 0.5;
    line-height: 11px;
  }

  img {
    height: 19px;
  }
`;

export const BlockGamesBanner = ({ className, ...props }: HTMLAttributes<HTMLDivElement>): JSX.Element => {
  return (
    <Wrapper {...props} className={`flex items-end ${className || ""}`}>
      <span>Published by</span>
      <img src={BGLogo} alt="" />
    </Wrapper>
  );
};
