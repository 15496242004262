import styled, { css } from "styled-components";

export const ButtonStyles = css`
  font-weight: 500;
  font-size: 14px;
  border-radius: 5px;
  outline: none;
  position: relative;
  background: none;
  appearance: none;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  box-sizing: border-box;
  border: none;
  background: #000000;
  height: 56px;
  padding: 15px 40px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
  color: #fff;

  &:disabled {
    opacity: 0.6;
  }
`;

export const Button = styled.button`
  ${ButtonStyles}
`;
