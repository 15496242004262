import { HTMLAttributes, useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { LogOutLink } from "./LogOutLink";
import { Button } from "../../../components/Button/Button";
import { firebaseAuth, firebaseFunc } from "../../../components/Firebase/Firebase";
import { User } from "firebase/auth";
import { LoaderIcon } from "../../../components/SVGs/LoaderIcon";
import { IPlayFabLoginResult } from "./IUserDashboard";

const ErrorMessageWrapper = styled.div`
  color: white;
`;

function formRedirectUrlFromPlayFab(result: any) {
  return (
    "bgid://auth?" +
    "pf_entitytoken=" +
    result.data.EntityToken.EntityToken +
    "&pf_sessionticket=" +
    result.data.SessionTicket +
    "&pf_playfabid=" +
    result.data.PlayFabId +
    "&pf_entityid=" +
    result.data.EntityToken.Entity.Id +
    "&pf_entitytype=" +
    result.data.EntityToken.Entity.Type
  );
}

interface IUserDashboard extends HTMLAttributes<HTMLDivElement> {
  user: User | undefined | null;
}

export const UserDashboard = ({ user, className, ...rest }: IUserDashboard) => {
  const navigate = useNavigate();
  const [tokenUrl, setTokenUrl] = useState<any | undefined>(undefined);
  const [getNewPlayFabSession, loadingPlayFabSession, errorPlayFabSession] = useHttpsCallable(
    firebaseFunc,
    "getNewPlayFabSession"
  );

  useEffect(() => {
    const createPlayFabSession = async () => {
      try {
        const result = (await getNewPlayFabSession({
          hostname: window.location.hostname,
        })) as { data: IPlayFabLoginResult } | undefined;

        if (result) {
          setTokenUrl(formRedirectUrlFromPlayFab(result));
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (user && !loadingPlayFabSession) {
      createPlayFabSession();
    }
    // eslint-disable-next-line
  }, [user]);

  const handleSignOut = async (e: any) => {
    e.preventDefault();
    await firebaseAuth.signOut();
    navigate("/");
  };

  if (loadingPlayFabSession) {
    return (
      <div {...rest} className={`flex flex-col justify-center ${className || ""}`}>
        <LoaderIcon className="mx-auto mb-5" />
        <LogOutLink onClick={handleSignOut} />
      </div>
    );
  }

  return (
    <div {...rest} className={`flex flex-col justify-center ${className || ""}`}>
      {errorPlayFabSession && (
        <ErrorMessageWrapper className="mb-5">
          <div>There were some issues with your account:</div>
          <div>{errorPlayFabSession.message}</div>
        </ErrorMessageWrapper>
      )}
      {tokenUrl && (
        <Button
          className="mb-5 w-full"
          style={{ minWidth: 300 }}
          onClick={() => {
            window.location.href = tokenUrl;
          }}
        >
          Go back to app
        </Button>
      )}
      <LogOutLink onClick={handleSignOut} />
    </div>
  );
};
