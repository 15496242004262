import { Button } from "../../components/Button/Button";
import GoogleLogo from "../../assets/images/google_logo.svg";
import { HTMLAttributes } from "react";

interface ISignInWithGoogle extends HTMLAttributes<HTMLDivElement> {
  onSignIn(): void;
}

export const SignInWithGoogle = ({ className, onSignIn, ...rest }: ISignInWithGoogle) => {
  return (
    <div {...rest} className={`flex flex-col ${className || ""}`}>
      <Button onClick={onSignIn} className="flex items-center">
        <img src={GoogleLogo} alt="Google Logo" className="mr-4" />
        Connect with Google
      </Button>
    </div>
  );
};
