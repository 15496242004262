import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { ErrorMessage as CustomErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import ErrorIcon from "../../../assets/images/error_icon.svg";
import SuccessIcon from "../../../assets/images/success_icon.svg";

const SignInFormValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

export interface IEmailSignFormValues {
  email: string;
  password: string;
}

export interface IEmailSignForm {
  onSubmit(values: IEmailSignFormValues, formikHelpers: FormikHelpers<IEmailSignFormValues>): void;
  submitText: string;
}

export const EmailSignForm = ({ onSubmit, submitText }: IEmailSignForm) => {
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={SignInFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
        <Form className="flex flex-col">
          <Input
            label="Email"
            name="email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            suffix={
              dirty &&
              (errors.email ? <img alt="Success Icon" src={SuccessIcon} /> : <img alt="Error Icon" src={ErrorIcon} />)
            }
          />

          <ErrorMessage
            name="email"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />
          <Input
            label="Password"
            name="password"
            type="password"
            className="mt-3"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />

          <ErrorMessage
            name="password"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />

          <Button className="mt-4" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
