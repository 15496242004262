import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { FormikHelpers } from "formik";
import { AuthErrorCodes } from "firebase/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/Firebase/Firebase";
import { EmailSignForm, IEmailSignFormValues } from "./forms/EmailSignInForm";
import { BackButton } from "./components/BackButton";
import { Link } from "../../components/Link/Link";

export const SignInWithEmail = () => {
  // eslint-disable-next-line
  const [signInWithEmailAndPassword, user, loading, error] = useSignInWithEmailAndPassword(firebaseAuth);
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleFormSubmit = async (
    { email, password }: IEmailSignFormValues,
    { setSubmitting }: FormikHelpers<IEmailSignFormValues>
  ) => {
    const success = await signInWithEmailAndPassword(email, password);
    if (success) {
      setSubmitting(false);
      state?.redirect ? navigate(`/${state?.redirect}`) : navigate("/");
      return;
    }

    Sentry.configureScope((scope) => scope.setLevel("error").setUser({ email }));
  };

  useEffect(() => {
    if (error) {
      switch (error.code) {
        case AuthErrorCodes.INVALID_PASSWORD:
          toast.error("Wrong password");
          return;
        case AuthErrorCodes.USER_DELETED:
          toast.error("We couldn't find this user");
          return;
        default:
          toast.error("Something has gone wrong. Please inform our support team via Discord");
          Sentry.captureException(error);
      }
    }
  }, [error]);

  return (
    <div>
      <div className="flex justify-between">
        <BackButton onClick={() => navigate("/")} />
        <Link to="/register">Register</Link>
      </div>
      <EmailSignForm submitText={loading ? "Signing in..." : "Sign in"} onSubmit={handleFormSubmit} />
      <div className="mt-5 text-white font-sm">
        Forgot password or already have an account with no password? <Link to="/reset-password">Reset password</Link>
      </div>
    </div>
  );
};
