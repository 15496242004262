import React from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import "./index.css";
import App from "./App";
import { initSentry } from "./utils/sentry";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

initSentry();
ReactGA.initialize("G-ZDWMRPFXXB");

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
