import { Button } from "../../components/Button/Button";
import { SignInWithGoogle } from "./SignInWithGoogle";
import EnvelopeIcon from "../../assets/images/envelope_icon.svg";

interface ISelectSignInMethod {
  onEmailClick(): void;
  onGoogleClick(): void;
}

export const SelectSignInMethod = ({ onEmailClick, onGoogleClick }: ISelectSignInMethod): JSX.Element => {
  return (
    <div className="flex flex-col">
      <SignInWithGoogle className="mb-14px" onSignIn={onGoogleClick} />
      <Button className="w-full flex align-middle items-center" as="a" onClick={onEmailClick}>
        <img src={EnvelopeIcon} alt="Envelope logo" className="mr-17px" />
        Connect with email
      </Button>
    </div>
  );
};
