import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";
import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { AuthErrorCodes } from "firebase/auth";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/Firebase/Firebase";
import { BackButton } from "./components/BackButton";
import { Link } from "../../components/Link/Link";
import { EmailRegisterForm, IEmailRegisterFormValues } from "./forms/EmailRegisterForm";

export const RegisterEmailAccount = () => {
  // eslint-disable-next-line
  const [createUserWithEmailAndPassword, user, loading, error] = useCreateUserWithEmailAndPassword(firebaseAuth);
  const navigate = useNavigate();

  const handleFormSubmit = async (
    { email, password }: IEmailRegisterFormValues,
    { setSubmitting }: FormikHelpers<IEmailRegisterFormValues>
  ) => {
    const success = await createUserWithEmailAndPassword(email, password);
    if (success) {
      setSubmitting(false);
      navigate("/");
      return;
    }

    Sentry.configureScope((scope) => scope.setLevel("error").setUser({ email }));
  };

  useEffect(() => {
    if (error) {
      switch (error.code) {
        case AuthErrorCodes.EMAIL_EXISTS:
          toast.error("This email already exists");
          return;
        case AuthErrorCodes.WEAK_PASSWORD:
          toast.error("Password should be at least 6 characters");
          return;
        default:
          toast.error("Something has gone wrong. Please inform our support team via Discord");
          Sentry.captureException(error);
      }
    }
  }, [error]);

  return (
    <>
      <div className="flex justify-between">
        <BackButton onClick={() => navigate("/")} />
        <Link to="/login">Login</Link>
      </div>
      <EmailRegisterForm submitText={loading ? "Registering..." : "Register"} onSubmit={handleFormSubmit} />
    </>
  );
};
