import { HTMLAttributes } from "react";
import styled from "styled-components";
import ChevronLeft from "../../../assets/images/chevron-left.svg";

const BackButtonWrapper = styled.div`
  color: white;
  display: inline-flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const BackButton = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <BackButtonWrapper {...props}>
      <img src={ChevronLeft} alt="Back arrow" className="mr-2" />
      Back
    </BackButtonWrapper>
  );
};
