import { Route, Routes, BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fractal } from "./pages/Fractal/Fractal";
import { Login } from "./pages/Login/Login";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/fractal" element={<Fractal />} />
          <Route path="/login" element={<Login />}></Route>
          <Route path="/*" element={<Login />}></Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
