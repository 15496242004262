import { useEffect, useState } from "react";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { Button } from "../../components/Button/Button";
import { firebaseFunc, firebaseAuth } from "../../components/Firebase/Firebase";
import { LoaderIcon } from "../../components/SVGs/LoaderIcon";
import { LogOutLink } from "../Login/components/LogOutLink";
import { useAuthState } from "react-firebase-hooks/auth";
import { IPlayFabLoginResult } from "../Login/components/IUserDashboard";
import { KarluqContainer } from "../Login/components/KarluqContainer";

export const Fractal = () => {
  const navigate = useNavigate();
  const [user, loading] = useAuthState(firebaseAuth);
  const [playFabID, setPlayFabID] = useState<string | undefined>(undefined);
  const [isFractalLinked, setIfFractalLinked] = useState(true);
  const [addFractalID, loadingAddFractalID, errorAddFractalID] = useHttpsCallable(firebaseFunc, "addFractalID");
  const [getIfFractalLinked, loadingGetFractalID] = useHttpsCallable(firebaseFunc, "getIfFractalLinked");
  const [getNewPlayFabSession, loadingPlayFabSession, errorPlayFabSession] = useHttpsCallable(
    firebaseFunc,
    "getNewPlayFabSession"
  );

  useEffect(() => {
    if (errorAddFractalID) {
      Sentry.captureException(errorAddFractalID);
      toast.error(errorAddFractalID?.message);
    }
  }, [errorAddFractalID]);

  useEffect(() => {
    const createPlayFabSession = async () => {
      try {
        const result = (await getNewPlayFabSession({
          hostname: window.location.hostname,
        })) as { data: IPlayFabLoginResult } | undefined;

        if (result) {
          setPlayFabID(result?.data?.PlayFabId);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (user && !loadingPlayFabSession) {
      createPlayFabSession();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const getFractalIDState = async () => {
      const requestResult = await getIfFractalLinked({ hostname: window.location.hostname });
      setIfFractalLinked(requestResult?.data as boolean);
    };

    if (loadingPlayFabSession || loadingGetFractalID || !playFabID) {
      return;
    }

    getFractalIDState();
    // eslint-disable-next-line
  }, [playFabID]);

  const handleSignOut = async (e: any) => {
    e.preventDefault();
    await firebaseAuth.signOut();
    navigate("/");
  };

  const handleFractal = async (e: any) => {
    const clientId = process.env.REACT_APP_FRACTAL_CLIENT_ID;
    const { data: authResponse } = await axios(
      `https://auth-api.fractal.is/auth/v2/approval/geturl?clientId=${clientId}&scope=identify`
    );

    if (authResponse?.url) {
      window.open(authResponse?.url, "_blank", "noreferrer");
    }

    const fractalPool = setInterval(async () => {
      const { data: authResultResponse } = await axios({
        method: "post",
        url: "https://auth-api.fractal.is/auth/v2/approval/result",
        data: { clientId, code: authResponse.code },
      });

      if (authResultResponse.userId) {
        clearInterval(fractalPool);
        await addFractalID({ fractalID: authResultResponse.userId, playFabID });
        setIfFractalLinked(true);
        toast.success("Successfully linked to your Fractal account!", { autoClose: false });
      }
    }, 4000);
  };

  if (loadingPlayFabSession || loadingGetFractalID || loading) {
    return (
      <KarluqContainer>
        <LoaderIcon />
      </KarluqContainer>
    );
  }

  if (!user && !loading) {
    return (
      <KarluqContainer>
        <div className="flex flex-col">
          <div className="text-white text-semibold font-xl mb-5">Please login before accessing this page.</div>
          <Button onClick={() => navigate("/login/method", { state: { redirect: "fractal" } })}>Login</Button>
        </div>
      </KarluqContainer>
    );
  }

  return (
    <KarluqContainer>
      <div className="w-full">
        <div className={`flex flex-col items-center justify-center`}>
          {errorPlayFabSession && (
            <div className="mb-5 text-white">
              <div>There were some issues with your account:</div>
              <div>{errorPlayFabSession.message}</div>
            </div>
          )}
          {!isFractalLinked && !errorPlayFabSession ? (
            <Button className="mb-5" onClick={handleFractal} disabled={loadingAddFractalID}>
              Link to fractal
            </Button>
          ) : (
            <div className="text-white text-semibold font-xl mb-5">Your account is linked to fractal!</div>
          )}
          <LogOutLink onClick={handleSignOut} />
        </div>
      </div>
    </KarluqContainer>
  );
};
