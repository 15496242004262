import { HTMLAttributes } from "react";
import styled from "styled-components";

const StyledErrorMessage = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 0.02em;
  color: #b50202;
`;

export const ErrorMessage = ({ children, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  return <StyledErrorMessage {...rest}>{children}</StyledErrorMessage>;
};
