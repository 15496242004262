import { HTMLAttributes } from "react";
import styled from "styled-components";
import { LogoutIcon } from "../../../components/SVGs/LogoutIcon";

const LogOutLinkWrapper = styled.div`
  color: white;
`;

export const LogOutLink = ({ className, ...rest }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <LogOutLinkWrapper
      {...rest}
      className={`inline-flex cursor-pointer justify-center hover:underline ${className || ""}`}
    >
      <LogoutIcon /> Sign-out
    </LogOutLinkWrapper>
  );
};
