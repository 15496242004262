import { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/Firebase/Firebase";
import { SuccessMessage } from "./components/SuccessMessage";
import { BackButton } from "./components/BackButton";
import { Link } from "../../components/Link/Link";
import { ResetPasswordForm, IPasswordResetFormValues } from "./forms/ResetPasswordForm";
import { AuthErrorCodes } from "firebase/auth";

const actionCodeSettings = {
  url: `${process.env.REACT_APP_FIREBASE_EMAIL_CONTINUE_URL}login`,
};

export const ResetEmailPassword = () => {
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(firebaseAuth);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleFormSubmit = async (
    { email }: IPasswordResetFormValues,
    { setSubmitting }: FormikHelpers<IPasswordResetFormValues>
  ) => {
    const success = await sendPasswordResetEmail(email, actionCodeSettings);

    if (success) {
      setSubmitting(false);
      setShowSuccessMessage(true);
      return;
    }

    Sentry.configureScope((scope) => scope.setLevel("error").setUser({ email }));
  };

  useEffect(() => {
    if (error && "code" in error) {
      switch (error.code) {
        case AuthErrorCodes.INVALID_PASSWORD:
          toast.error("Wrong password");
          return;
        case AuthErrorCodes.USER_DELETED:
          toast.error("We couldn't find this user");
          return;
        default:
          toast.error("Something has gone wrong. Please inform our support team via Discord");
          Sentry.captureException(error);
      }
    }
  }, [error]);

  return (
    <div>
      <div className="flex justify-between">
        <BackButton onClick={() => navigate("/")} />
        <Link to="/login">Login</Link>
      </div>
      {showSuccessMessage ? (
        <SuccessMessage message="Reset email has been send to your inbox." />
      ) : (
        <ResetPasswordForm onSubmit={handleFormSubmit} submitText={sending ? "Sending..." : "Reset password"} />
      )}
    </div>
  );
};
