import { Formik, Form, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button } from "../../../components/Button/Button";
import { Input } from "../../../components/Input/Input";
import { ErrorMessage as CustomErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import ErrorIcon from "../../../assets/images/error_icon.svg";
import SuccessIcon from "../../../assets/images/success_icon.svg";

const ResetPasswordFormValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export interface IPasswordResetFormValues {
  email: string;
}

export interface IPasswordResetForm {
  onSubmit(values: IPasswordResetFormValues, formikHelpers: FormikHelpers<IPasswordResetFormValues>): void;
  submitText: string;
}

export const ResetPasswordForm = ({ onSubmit, submitText }: IPasswordResetForm) => {
  return (
    <Formik initialValues={{ email: "" }} validationSchema={ResetPasswordFormValidationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
        <Form className="flex flex-col">
          <Input
            label="Email"
            name="email"
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            suffix={
              dirty &&
              (errors.email ? <img alt="Success Icon" src={SuccessIcon} /> : <img alt="Error Icon" src={ErrorIcon} />)
            }
          />

          <ErrorMessage
            name="email"
            render={(msg) => <CustomErrorMessage className="ml-5 mt-1">{msg}</CustomErrorMessage>}
          />

          <Button className="mt-4" type="submit" disabled={isSubmitting}>
            {submitText}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
