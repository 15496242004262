import { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { firebaseAuth } from "../../components/Firebase/Firebase";
import { Button } from "../../components/Button/Button";
import { Input } from "../../components/Input/Input";
import { ErrorMessage as CustomErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import ChevronLeft from "../../assets/images/chevron-left.svg";
import ErrorIcon from "../../assets/images/error_icon.svg";
import SuccessIcon from "../../assets/images/success_icon.svg";
import { SuccessMessage } from "./components/SuccessMessage";

const BackButton = styled.div`
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const actionCodeSettings = {
  url: `${process.env.REACT_APP_FIREBASE_EMAIL_CONTINUE_URL}create-new-password`,
};

const EmailValidationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

export const CreateNewPassword = () => {
  // eslint-disable-next-line
  const [sendPasswordResetEmail, sending, error] = useSendPasswordResetEmail(firebaseAuth);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return (
    <div>
      <BackButton onClick={() => navigate("/")}>
        <img src={ChevronLeft} alt="Back arrow" className="mr-2" />
        Back
      </BackButton>
      {showSuccessMessage ? (
        <SuccessMessage message="Reset email has been send to your inbox." />
      ) : (
        <Formik
          initialValues={{ email: "" }}
          validationSchema={EmailValidationSchema}
          onSubmit={async ({ email }, { setSubmitting }) => {
            const success = await sendPasswordResetEmail(email, actionCodeSettings);

            if (success) {
              setSubmitting(false);
              setShowSuccessMessage(true);
              return;
            }
          }}
        >
          {({ isSubmitting, handleChange, handleBlur, values, errors, dirty }) => (
            <Form className="flex flex-col">
              <Input
                label="Email"
                name="email"
                className="mb-3"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                suffix={
                  dirty &&
                  (errors.email ? (
                    <img alt="Success Icon" src={SuccessIcon} />
                  ) : (
                    <img alt="Error Icon" src={ErrorIcon} />
                  ))
                }
              />

              <ErrorMessage
                name="email"
                render={(msg) => <CustomErrorMessage className="ml-5">{msg}</CustomErrorMessage>}
              />

              <Button className="mt-3" type="submit" disabled={isSubmitting}>
                {sending ? "Sending..." : "Reset password"}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
